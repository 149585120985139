*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

body{
  background: #16222A;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
  
}

.App {
  text-align: center;
  padding-top: 10%;
  padding-left: 30%;
  padding-right: 30%;
 
}

header {
  margin-bottom: 17px;
  font-family: cursive;
}

.list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: justify;
  color: #fff;
  margin: 30px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  font-size: 12px;
  font-family: sans-serif;
  
}

button {
  margin-left: 10px;
  border: none;
  background: transparent;

}

.items {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 10px;
}
img{
  width: 40px;
  height: 40px;
}

.inp-field {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list input {
  width: 25px;
  height: 25px;
}

.inp-field input {
  height: 30px;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 8px;
  text-align: center;
}

small {
  color: red;
  font-weight: 700;
  margin-bottom: 70px;
}

.through-line {
  text-decoration: line-through;
  color: lightcoral;
}